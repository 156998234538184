import React, { useState, useEffect } from "react";
import { CgClose } from "react-icons/cg";
import { BiArrowBack } from "react-icons/bi";
import { VscCheckAll } from "react-icons/vsc";

const CartWindow = ({
  isOpen,
  onClose,
  totalPrice,
  cartItems,
  productQuantity,
  decreaseQuantity,
  increaseQuantity
}) => {
  const [calculatedPrice, setCalculatedPrice] = useState(totalPrice);
  const [inquiryPopup, setInquiryPopup] = useState(false);
  const [formLoading, setFormLoading] = useState(true);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    let total = 0;
    cartItems.forEach((item) => {
      total += item.price * (productQuantity[item.id] || 0);
    });
    setCalculatedPrice(total);
  }, [cartItems, productQuantity]);

  useEffect(() => {
    // Add event listener for closing the cart window when clicking outside
    const handleOutsideClick = (e) => {
      if (!e.target.closest(".cart-popup-content")) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen, onClose]);

  const handleViewCart = () => {
    setInquiryPopup(true);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const description = cartItems.map((item) => ({
      item: item.name,
      quantity: productQuantity[item.id],
      price: item.price,
      total: productQuantity[item.id] * item.price,
    }));
    const formData = new FormData(event.target);
    const dataToSend = {
      description: {
        description: "Order Details",
        phone: `${formData.get("phone")}`,
        name: `${formData.get("name")}`,
        address: `${formData.get("address")}`,
        items: description,
      },
      subject: `${formData.get("subject")}`,
      email: "marimuthu@piperedge.com",
    };

    try {
      const response = await fetch(
        "https://shop.pricefinder.in/wp-json/custom/v1/submit-freshdesk-ticket",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataToSend),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        console.log("CartItems sent successfully:", responseData);
        setLoading(false);
        setFormLoading(false);
      } else {
        console.error("Failed to send cartItems");
      }
    } catch (error) {
      console.error("Error sending cartItems:", error);
      setLoading(false);
    }
  };

  const handleOverlayClick = (event) => {
    if (event.target.classList.contains("inquiry_overlay")) {
      handleCloseClick();
    }
  };

  const handleCloseClick = () => {
    setInquiryPopup(false);
    setFormLoading(true);
  };

  return (
    <>
      <div className={`cart-backdrop ${isOpen ? "open" : ""}`}></div>
      <div className={`cart-popup ${isOpen ? "open" : ""}`}>
        <div className="cart-popup-content">
          <div className="popup__header">
            <h4>YOUR CART</h4>
            <span onClick={onClose}>
              <CgClose />
            </span>
          </div>
          <span className="countinue__shoping" onClick={onClose}>
            <span>
              <BiArrowBack />
              Countinue Shopping
            </span>
          </span>
          <hr />

          {cartItems.length > 0 ? (
            <div>
              {cartItems.map((value, index) => (
                <>
                  <div className="cart__product">
                    <div className="cart__image">
                      {value.image ? (
                        <img
                          src={value.image}
                          className="card-img-top product-image"
                          alt={value.name}
                        />
                      ) : (
                        <img
                          src={value.images[0].src}
                          className="card-img-top product-image"
                          alt={value.name}
                        />
                      )}
                    </div>
                    <div className="cart__productdetails">
                      <h6>{value.name}</h6>
                      <p>₹{Number(value.regular_price).toFixed(2)}</p>
                      <div className="plus__minus">
                        <div
                          className={`total_add ${productQuantity[value.id] > 0 ? "active" : ""
                            }`}
                        >
                          {productQuantity[value.id] > 1 ? (
                            <div
                              className="minus_cart"
                              onClick={() => decreaseQuantity(value.id)}
                            >
                              -
                            </div>
                          ) : (
                            <div className="minus">-</div>
                          )}
                          {productQuantity[value.id] > 0 && (
                            <div className="item_count_cart">
                              {productQuantity[value.id]}
                            </div>
                          )}
                          <div
                            className="plus_cart"
                            onClick={() => increaseQuantity(value.id)}
                          >
                            +
                          </div>
                        </div>
                        {/* <p onClick={() => removeItem(value.id)}>Remove</p> */}
                        <p className="subtotal">
                          {productQuantity[value.id]} * ₹{value.regular_price} = ₹{productQuantity[value.id] * value.regular_price}
                        </p>
                      </div>
                    </div>
                  </div>
                  <hr />
                </>
              ))}
              <div className="total_cart_price">
                <div className="total_cart_checkout" onClick={handleViewCart}>
                  <span>Place Order </span>
                  <span className="checkout_total_price">
                    ₹{totalPrice.toFixed(2)}
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <h2 className="cart__empty">Your cart is currently</h2>
              <h2 className="cart__emptyemoji">empty!!</h2>
            </div>
          )}
        </div>
      </div>
      {inquiryPopup && (
        <div className="inquiry_overlay" onClick={handleOverlayClick}>
          <div className="inquiry_popup">
            <div className="popup__header">
              <button className="close_button" onClick={handleCloseClick}>
                <CgClose />
              </button>
              <div className="product__logo">
                {/* <img src={selectedProduct.image} alt="" /> */}
              </div>
            </div>

            {formLoading && (
              <div className="heading_close">
                <div className="heading_popup">
                  <h4>Enter the Details</h4>
                </div>
              </div>
            )}

            {formLoading ? (
              <form onSubmit={handleSubmit} method="POST" className="form form_address">
                <input
                  type="text"
                  name="name"
                  placeholder="Your Name (required)"
                  required
                />
                <input
                  type="hidden"
                  name="subject"
                  value={"Orders"
                    // selectedProduct.vendor_name + " - " + selectedProduct.name
                  }
                />
                <div className="input__email">
                  <input
                    type="email"
                    name="email"
                    placeholder="Your Email (required)"
                    required
                  />
                  <input
                    type="phone"
                    name="phone"
                    placeholder="Phone Number (required)"
                    required
                  />
                </div>
                <textarea
                  type="text"
                  className="shipping_address_textarea"
                  name="address"
                  required
                  placeholder="Your Shipping Address (required)"
                ></textarea>
                <div className="h-captcha" data-captcha="true"></div>
                <button type="submit" disabled={loading}>
                  {loading ? "Submitting..." : "Submit"}
                </button>
              </form>
            ) : (
              <div className="thankyou__message">
                <div className="icon">
                  <VscCheckAll />
                </div>
                <p>
                  Thank you for your order! We'll be in touch shortly.
                </p>
                <button
                  className="btn btn-success center"
                  onClick={handleCloseClick}
                >
                  Close
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CartWindow;
