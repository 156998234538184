// Import required modules and components
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { GoVerified } from "react-icons/go";
import DefaultImage from "../../assets/default.png";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { CgClose } from "react-icons/cg";
import { VscCheckAll } from "react-icons/vsc";
import axios from "axios";
import Footer from "../Footer/Footer";
import "./HomePage.css";

// Define Home component
function Home() {
  // State variables for managing product data and search inputs
  const [productValues, setProductValues] = useState([]);
  const [pages, setPages] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchCity, setSearchCity] = useState("");
  const [, setIsSuggestionSelected] = useState(false);
  const [number, setnumber] = useState(0);
  const [productSuggestionShow, setProductSuggestionShow] = useState([]);
  const [selectedProductSuggestion, setSelectedProductSuggestion] =
    useState(-1);
  const [citySuggestionShow, setCitySuggestionShow] = useState([]);
  const [selectedCitySuggestion, setSelectedCitySuggestion] = useState(-1);
  const [previousCitySuggestions, setPreviousCitySuggestions] = useState([]);
  const [previousCity, setPreviousCity] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [inquiryPopup, setinquiryPopup] = useState();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(true);

  // Refs to handle click outside suggestions
  const productSuggestionRef = useRef(null);
  const citySuggestionRef = useRef(null);
  const productShowRef = useRef(null);

  // Function to fetch product suggestions from API
  const fetchProductSuggestions = async (inputText) => {
    try {
      const consumerKey = process.env.REACT_APP_CONSUMER_KEY;
      const consumerSecret = process.env.REACT_APP_CONSUMER_SECRET;

      const url = `https://shop.pricefinder.in/wp-json/custom-wc/v1/tag-suggestions?input=pro-${inputText}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
        },
      });

      const productDetails = response.data;
      setProductSuggestionShow(productDetails);
      setSelectedProductSuggestion(-1);
    } catch (error) {
      console.error("Error fetching product suggestions:", error);
    } finally {
    }
  };

  // Function to fetch city suggestions from API
  const fetchCitySuggestions = async (citysug) => {
    try {
      const consumerKey = process.env.REACT_APP_CONSUMER_KEY;
      const consumerSecret = process.env.REACT_APP_CONSUMER_SECRET;

      const url = `https://shop.pricefinder.in/wp-json/custom/v1/tag-suggestions1?input=pro-${citysug}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
        },
      });
      const cityDetails = response.data;
      setPreviousCitySuggestions(cityDetails);
      setCitySuggestionShow(cityDetails);
      setSelectedCitySuggestion(-1);
    } catch (error) {
      console.error("Error fetching city suggestions:", error);
    }
  };

  // Function to handle click on product suggestion
  const handleProductSuggestionClick = (sug) => {
    setSearchText(sug);
    fetchProductDetails(sug);
    setIsSuggestionSelected(true);
    setnumber(1);
    setProductSuggestionShow([]);
    setSelectedProductSuggestion(-1);
    fetchCitySuggestions(sug);
  };

  // Function to handle click on city suggestion
  const handleCitySuggestionClick = (sug) => {
    setSearchCity(sug);
    if (searchText !== "") {
      fetchProductDetailsByCity(sug, searchText);
    }
    setIsSuggestionSelected(false);
  };

  // Function to handle keyboard navigation within product suggestions
  const handleProductSuggestionNavigation = (e) => {
    const suggestions = productSuggestionShow;
    const lastIndex = suggestions.length - 1;

    switch (e.key) {
      case "ArrowUp":
        e.preventDefault();
        setSelectedProductSuggestion((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : lastIndex
        );
        break;
      case "ArrowDown":
        e.preventDefault();
        setSelectedProductSuggestion((prevIndex) =>
          prevIndex < lastIndex ? prevIndex + 1 : 0
        );
        break;
      case "Enter":
        e.preventDefault();
        if (selectedProductSuggestion !== -1) {
          const selectedSuggestionText = suggestions[selectedProductSuggestion];
          handleProductSuggestionClick(selectedSuggestionText);
        }
        break;
      default:
        break;
    }
  };

  function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    // Collect form data
    const formData = new FormData(event.target);

    // Create the jsonObject based on the desired format
    const jsonObject = {
      description: {
        description: `${formData.get("description")}`,
        phone: `${formData.get("phone")}`,
      },
      subject: `${formData.get("subject")}`,
      email: `${formData.get("email")}`,
    };

    // Send POST request to your desired endpoint
    fetch(
      "https://shop.pricefinder.in/wp-json/custom/v1/submit-freshdesk-ticket",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(jsonObject),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("Form submitted successfully:", data);
        console.log(jsonObject);
        setLoading(false);
        setFormLoading(false);
        // setSelectedProduct(null);
        // setinquiryPopup(false);
        // alert("Thank you for your inquiry! We will get back to you soon.");
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
        setLoading(false);
      });
  }

  // Function to handle keyboard navigation within city suggestions
  const handleCitySuggestionNavigation = (e) => {
    const suggestions = citySuggestionShow;
    const lastIndex = suggestions.length - 1;

    switch (e.key) {
      case "ArrowUp":
        e.preventDefault();
        setSelectedCitySuggestion((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : lastIndex
        );
        break;
      case "ArrowDown":
        e.preventDefault();
        setSelectedCitySuggestion((prevIndex) =>
          prevIndex < lastIndex ? prevIndex + 1 : 0
        );
        break;
      case "Enter":
        e.preventDefault();
        if (selectedCitySuggestion !== -1) {
          const selectedSuggestionText = suggestions[selectedCitySuggestion];
          handleCitySuggestionClick(selectedSuggestionText);
        }
        break;
      default:
        break;
    }
  };

  // Function to fetch product details from API based on search text
  const fetchProductDetails = async (sug) => {
    try {
      const consumerKey = process.env.REACT_APP_CONSUMER_KEY;
      const consumerSecret = process.env.REACT_APP_CONSUMER_SECRET;

      const url = `https://shop.pricefinder.in/wp-json/custom-wc/v1/vendor-products-by-tags?vendor_id=9&tags[]=pro-${sug}&page=${number}&per_page=10`;

      const response = await axios.get(url, {
        headers: {
          Authorization: `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
        },
      });

      const productDetails = response.data;
      setPages(productDetails.total_pages);
      setProductValues(productDetails.products);
      // setCurrentPage(productDetails.current_page);
    } catch (error) {
      console.error("Error fetching product details:", error);
    }
  };

  // Function to handle next page button click
  const handleNextPage = () => {
    setnumber(number + 1);
    productShowRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  // Function to handle previous page button click
  const handlePreviousPage = () => {
    setnumber(number - 1);
    productShowRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  // Function to fetch product details based on city and product search text
  const fetchProductDetailsByCity = async (sug, searchText) => {
    setPages(0);
    try {
      const consumerKey = process.env.REACT_APP_CONSUMER_KEY;
      const consumerSecret = process.env.REACT_APP_CONSUMER_SECRET;
      const cityTag = `city-${sug}`;
      const productTag = `pro-${searchText}`;
      const url = `https://shop.pricefinder.in/wp-json/custom-wc/v1/products-by-tags?tags[]=${cityTag}&tags[]=${productTag}&page=${number}&per_page=10`;

      const response = await axios.get(url, {
        headers: {
          Authorization: `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
        },
      });

      const productDetails = response.data;
      setProductValues(productDetails.products);
      setPages(productDetails.total_pages);
    } catch (error) {
      console.error("Error fetching product details:", error);
    }
  };

  // Function to handle the last updated date
  const handleDate = (dateStr) => {
    const currentDate = new Date();

    // const day = currentDate.getDate().toString().padStart(2, "0");
    // const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    // const year = currentDate.getFullYear().toString();
    // const hours = currentDate.getHours().toString().padStart(2, "0");
    // const minutes = currentDate.getMinutes().toString().padStart(2, "0");
    // const seconds = currentDate.getSeconds().toString().padStart(2, "0");

    const [inputDate, inputTime] = dateStr.split(" ");
    const [inputDay, inputMonth, inputYear] = inputDate.split("-").map(Number);
    const [inputHours, inputMinutes, inputSeconds] = inputTime
      .split(":")
      .map(Number);

    const inputDateObject = new Date(
      inputYear,
      inputMonth - 1,
      inputDay,
      inputHours,
      inputMinutes,
      inputSeconds
    );

    const timeDifference = currentDate - inputDateObject;
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    if (daysDifference < 1) {
      const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
      if (hoursDifference < 1) {
        const minutesDifference = Math.floor(timeDifference / (1000 * 60));
        if (minutesDifference < 1) {
          const secondsDifference = Math.floor(timeDifference / 1000);
          return `${secondsDifference} seconds`;
        }
        return `${minutesDifference} minutes`;
      }
      return `${hoursDifference} hours`;
    }

    return `${daysDifference} days`;
  };

  const handleInquiry = (vendor) => {
    setSelectedProduct(vendor);
    setinquiryPopup(true);
  };

  const handleCloseClick = () => {
    setSelectedProduct(null);
    setinquiryPopup(false);
    setFormLoading(true);
  };

  const handleOverlayClick = (event) => {
    if (event.target.classList.contains("inquiry_overlay")) {
      handleCloseClick();
    }
  };

  // Effect to handle click outside suggestions and close them
  useEffect(() => {
    // Function to handle outside click
    const handleOutsideClick = (event) => {
      if (
        (productSuggestionRef.current &&
          !productSuggestionRef.current.contains(event.target) &&
          event.target !== document.getElementById("search-input")) ||
        (citySuggestionRef.current &&
          !citySuggestionRef.current.contains(event.target) &&
          event.target !== document.getElementById("city-search-input"))
      ) {
        setIsSuggestionSelected(false);
        setProductSuggestionShow([]);
        setCitySuggestionShow([]);
      }
    };

    // Add event listener for outside click
    document.addEventListener("click", handleOutsideClick);

    // Remove event listener on component unmount
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  // Fetch product details on initial mount and whenever 'number' changes
  useEffect(() => {
    if (searchCity) {
      fetchProductDetailsByCity(searchCity, searchText);
    } else {
      fetchProductDetails(searchText);
    }
  }, [number]);

  useEffect(() => {
    if (searchCity !== previousCity && searchCity !== "") {
      setnumber(1);
    }
    setPreviousCity(searchCity);
  }, [searchCity]);

  return (
    <>
      {/* Home Page Content */}
      <section ref={productShowRef} id="main_home">
        <div className="home">
          <div className="home_content">
            <div>
              <h1>Find, Compare, and Shop with Ease</h1>
            </div>
            <div>
              <p>
                PriceFinder.in - Your Ultimate Destination for Local Product
                Search and Shopping!
              </p>
            </div>

            <div className="home_search">
              <div className="search_category">
                <input
                  type="search"
                  placeholder="Search product...."
                  list="productList"
                  value={searchText}
                  id="search-input"
                  onChange={(e) => {
                    const inputText = e.target.value.toLowerCase();
                    setSearchText(inputText);
                    fetchProductSuggestions(inputText);
                    setIsSuggestionSelected(false);
                  }}
                  onKeyDown={handleProductSuggestionNavigation}
                />

                <ul className="suggestions" ref={productSuggestionRef}>
                  {productSuggestionShow.map((sug, index) => {
                    const isSelected = index === selectedProductSuggestion;
                    return (
                      <li
                        onClick={() => handleProductSuggestionClick(sug)}
                        key={sug}
                        className={isSelected ? "selected" : ""}
                      >
                        {sug}
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="search_city">
                <input
                  type="search"
                  placeholder="Search city...."
                  list="cityList"
                  value={searchCity}
                  id="city-search-input"
                  onChange={(e) => {
                    const inputText = e.target.value.toLowerCase();
                    setSearchCity(inputText);
                    const filteredSuggestions = previousCitySuggestions.filter(
                      (suggestion) =>
                        suggestion.toLowerCase().startsWith(inputText)
                    );
                    setCitySuggestionShow(filteredSuggestions);
                    setIsSuggestionSelected(false);
                  }}
                  onClick={() => {
                    setCitySuggestionShow(previousCitySuggestions);
                    setIsSuggestionSelected(false);
                  }}
                  onKeyDown={handleCitySuggestionNavigation}
                  disabled={previousCitySuggestions.length < 1}
                />
                <ul className="suggestions" ref={citySuggestionRef}>
                  {citySuggestionShow.map((sug, index) => {
                    const isSelected = index === selectedCitySuggestion;
                    return (
                      <li
                        onClick={() => handleCitySuggestionClick(sug)}
                        key={sug}
                        className={isSelected ? "selected" : ""}
                      >
                        {sug}
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="search_button">
                <span>
                  <svg
                    width="25"
                    height="25"
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M10 17a7 7 0 1 0 0-14 7 7 0 0 0 0 14Z"></path>
                    <path d="m21 21-6-6"></path>
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* Table showing product details */}
        <div className="product_show_1">
          <div className="product-show">
            <div className="table_product">
              <div className="row table_for_bigscreen">
                {/* <div className="col-lg-12 col-sm-12"></div> */}
                <div className="col-lg-12 col-sm-12">
                  <div className="mt-5">
                    <table className="table table-striped table-hover table-fixed tableshow">
                      <thead className="thead table_heading">
                        <tr>
                          <th>Image</th>
                          <th>Name</th>
                          <th>Price</th>
                          <th>Last Updated</th>
                          {productValues.some(
                            (product) => product.vendor_name !== "Price Finder"
                          ) && <th>Vendor Name</th>}
                          {/* <th>View More</th> */}
                          {productValues.some(
                            (product) => product.vendor_name !== "Price Finder"
                          ) && <th>Visit</th>}
                          <th>Inquiry</th>
                        </tr>
                      </thead>
                      <tbody className="tbody">
                        {/* Map through productValues array to render product details */}
                        {productValues.map((product, index) => (
                          <tr key={index} className="tr_tables">
                            <td>
                              <div className="img-container">
                                {/* Render product image or default image if not available */}
                                {product.image ? (
                                  <img
                                    src={product.image}
                                    alt="Product"
                                    className="img"
                                    loading="lazy"
                                  />
                                ) : (
                                  <>
                                    {product.images > 0 ? (
                                      <img
                                        src={product.images[0].src}
                                        alt="Default Product"
                                        className="img"
                                      />
                                    ) : (
                                      <img
                                        src={DefaultImage}
                                        alt="Default Product"
                                        className="img"
                                      />
                                    )}
                                  </>
                                )}
                                {/* Enlarged image */}
                                <div className="img-enlarged">
                                  {product.image ? (
                                    <img src={product.image} alt="Product" />
                                  ) : (
                                    <>
                                      {product.images > 0 ? (
                                        <img
                                          src={product.images[0].src}
                                          alt="Default Product"
                                        />
                                      ) : (
                                        <img
                                          src={DefaultImage}
                                          alt="Default Product"
                                        />
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </td>
                            <td>
                              {product.name}{" "}
                              {product.verified == "yes" && (
                                <span title="Verified">
                                  <GoVerified className="verified" />
                                </span>
                              )}
                            </td>
                            <td>
                              {product.regular_price != "" &&
                              product.regular_price > product.sale_price ? (
                                <span>
                                  ₹{Number(product.regular_price).toFixed(2)}
                                </span>
                              ) : (
                                <span>
                                  ₹{Number(product.sale_price).toFixed(2)}
                                </span>
                              )}{" "}
                              {product.regular_price != "" &&
                              product.regular_price < product.sale_price ? (
                                <span
                                  style={{ textDecoration: "line-through" }}
                                >
                                  ₹{Number(product.regular_price).toFixed(2)}
                                </span>
                              ) : null}
                            </td>
                            <td>{handleDate(product.date_modified)} ago</td>
                            {/* {names.store.vendor_display_name && <span>{names.store.vendor_display_name}</span>} */}
                            {product.vendor_name &&
                              product.vendor_name != "Price Finder" && (
                                <td>
                                  {" "}
                                  <span>{product.vendor_name}</span>
                                </td>
                              )}

                            {/* <td>
                            
                              <Link
                                className=""
                                to={names.permalink}
                                target="_blank"
                              >
                                <button className="btn btn-success">
                                  View Product
                                </button>
                              </Link>
                            </td> */}

                            {product.vendor_name &&
                              product.vendor_name != "Price Finder" && (
                                <td>
                                  <Link
                                    className="vendor_link"
                                    to={`/store/${product.vendor_id}`}
                                    target="_blank"
                                  >
                                    <button className="btn btn-success">
                                      Visit Store
                                    </button>
                                  </Link>
                                </td>
                              )}

                            <td>
                              <button
                                className="btn btn-success"
                                onClick={() => handleInquiry(product)}
                              >
                                <AiOutlineQuestionCircle /> Inquiry
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="row table_for_responsive">
              <div className="col-lg-12 col-sm-12"></div>
              <div className="col-lg-11 col-sm-12">
                <div className="mt-5">
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead className="thead-light">
                        <tr>
                          <th>Image</th>
                          <th>Details</th> {/* Modified column heading */}
                        </tr>
                      </thead>
                      <tbody className="tbody">
                        {productValues.map((names, index) => (
                          <tr key={index} className="tr_tables">
                            <td>
                              <div className="img-container">
                                {names.image ? (
                                  <img
                                    src={names.image}
                                    alt="Product"
                                    className="img"
                                  />
                                ) : (
                                  <>
                                    {names.images > 0 ? (
                                      <img
                                        src={names.images[0].src}
                                        alt="Default Product"
                                        className="img"
                                      />
                                    ) : (
                                      <img
                                        src={DefaultImage}
                                        alt="Default Product"
                                        className="img"
                                      />
                                    )}
                                  </>
                                )}
                                <div className="img-enlarged">
                                  {names.image ? (
                                    <img src={names.image} alt="Product" />
                                  ) : (
                                    <>
                                      {names.images > 0 ? (
                                        <img
                                          src={names.images[0].src}
                                          alt="Default Product"
                                        />
                                      ) : (
                                        <img
                                          src={DefaultImage}
                                          alt="Default Product"
                                        />
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </td>
                            <td>
                              <div>
                                <strong>Name:</strong> {names.name}{" "}
                                {names.verified == "yes" && (
                                  <span title="Verified">
                                    <GoVerified className="verified" />
                                  </span>
                                )}
                              </div>
                              <div>
                                <strong>Price:</strong>{" "}
                                {names.regular_price != "" &&
                                names.regular_price > names.sale_price ? (
                                  <span>
                                    ₹{Number(names.regular_price).toFixed(2)}
                                  </span>
                                ) : (
                                  <span>
                                    ₹{Number(names.sale_price).toFixed(2)}
                                  </span>
                                )}{" "}
                                {names.regular_price != "" &&
                                names.regular_price < names.sale_price ? (
                                  <span
                                    style={{ textDecoration: "line-through" }}
                                  >
                                    ₹{Number(names.regular_price).toFixed(2)}
                                  </span>
                                ) : null}
                                <p>
                                  <strong>Last Updated: </strong>
                                  {handleDate(names.date_modified)} ago
                                </p>
                              </div>

                              <div className="button_for_responsive">
                                <div className="mt-2">
                                  {/* <Link
                                    className=""
                                    to={names.permalink}
                                    target="_blank"
                                  >
                                    <button className="btn btn-success">
                                      View Prod
                                    </button>
                                  </Link> */}
                                </div>
                                <div className="mt-2">
                                  {names.vendor_name ? (
                                    <Link
                                      className="vendor_link"
                                      to={names.vendor_store_url}
                                      target="_blank"
                                    >
                                      <button className="btn btn-success">
                                        Visit Store
                                      </button>
                                    </Link>
                                  ) : (
                                    <Link
                                      className="vendor_link"
                                      to={`https://shop.pricefinder.in/store/pricefinder`}
                                      target="_blank"
                                    >
                                      <button className="btn btn-success">
                                        Visit Store
                                      </button>
                                    </Link>
                                  )}
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="navigation_button">
          <div className="page_nav">
            {number > 1 && pages > 1 ? (
              <button
                className="btn btn-success button_previous"
                onClick={handlePreviousPage}
              >
                Previous
              </button>
            ) : (
              <button className="btn btn-success button_previous disabled">
                Previous
              </button>
            )}
            <span className="page__number">
              {number} of {pages}
            </span>
            {pages > number ? (
              <button
                className="btn btn-success button_next"
                onClick={handleNextPage}
              >
                Next
              </button>
            ) : (
              <button className="btn btn-success button_next disabled">
                Next
              </button>
            )}
          </div>
        </div>
        {inquiryPopup && selectedProduct && (
          <div className="inquiry_overlay" onClick={handleOverlayClick}>
            <div className="inquiry_popup">
              <div className="popup__header">
                <div className="popup__product">
                  <h3>{selectedProduct.name}</h3>
                  {selectedProduct.sale_price ? (
                    <h3>₹{selectedProduct.sale_price}</h3>
                  ) : (
                    <h3>₹{selectedProduct.regular_price}</h3>
                  )}
                </div>
                <button className="close_button" onClick={handleCloseClick}>
                  <CgClose />
                </button>
                <div className="product__logo">
                  <img src={selectedProduct.image} alt="" />
                </div>
              </div>

              {formLoading && (
                <div className="heading_close">
                  <div className="heading_popup">
                    <h4>Ask a Question</h4>
                  </div>
                </div>
              )}

              {formLoading ? (
                <form onSubmit={handleSubmit} method="POST" className="form">
                  <input
                    type="text"
                    name="name"
                    placeholder="Your Name (required)"
                    required
                  />
                  <input
                    type="hidden"
                    name="subject"
                    value={
                      selectedProduct.vendor_name + " - " + selectedProduct.name
                    }
                  />
                  <div className="input__email">
                    <input
                      type="email"
                      name="email"
                      placeholder="Your Email (required)"
                      required
                    />
                    <input
                      type="phone"
                      name="phone"
                      placeholder="Phone Number (required)"
                      required
                    />
                  </div>
                  <textarea
                    type="text"
                    className="textarea"
                    name="description"
                    required
                    placeholder="Your Message (required)"
                  ></textarea>
                  <div className="h-captcha" data-captcha="true"></div>
                  <button type="submit" disabled={loading}>
                    {loading ? "SENDING..." : "SEND"}
                  </button>
                </form>
              ) : (
                <div className="thankyou__message">
                  <div className="icon">
                    <VscCheckAll />
                  </div>
                  <p>
                    Thank you for your inquiry! We will get back to you soon.
                  </p>
                  <button
                    className="btn btn-success center"
                    onClick={handleCloseClick}
                  >
                    Close
                  </button>
                </div>
              )}
            </div>
          </div>
        )}

        <Footer />
      </section>
    </>
  );
}

// Export the Home component
export default Home;
