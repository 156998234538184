import React, { useState } from "react";
import "./nav.css";
import { Link } from "react-router-dom";
import Logo from "../../assets/Logo.png";

function Navigation() {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
      <section id="main_nav">
        <div className="nav_content">
          <div className="nav_hamburger_design">
            <div className="logo">
              <Link to="/" style={{ textDecoration: "none" }}>
                <img src={Logo} alt="logo" />
              </Link>
            </div>
            {/* Hamburger icon on the right */}
            <div
              className={`hamburger-icon ${showMenu ? "active" : ""}`}
              onClick={() => setShowMenu(!showMenu)}
            >
              <div className="line line-1"></div>
              <div className="line line-2"></div>
              <div className="line line-3"></div>
            </div>
          </div>
          <div className="nav-others">
            {showMenu ? (
              <div
                className={`nav_others_all_mobile ${
                  showMenu ? "show-menu" : ""
                }`}
              >
                 <Link
                  to="/store"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <div>Store</div>
                </Link>
                <Link
                  to="https://shop.pricefinder.in/contact-us/"
                  style={{ textDecoration: "none", color: "black" }}
                  target="_blank"
                >
                  <div>Contact</div>
                </Link>
                <Link
                  to="https://shop.pricefinder.in/vendor-register/"
                  style={{ textDecoration: "none", color: "black" }}
                  target="_blank"
                >
                  <button className="btn btn-success button_navigation_1">
                    Become a seller
                  </button>
                </Link>
                <Link
                  to="https://shop.pricefinder.in/my-account/"
                  style={{ textDecoration: "none", color: "black" }}
                  target="_blank"
                >
                  <button className="btn btn-success button_navigation_2">
                    Login
                  </button>
                </Link>
              </div>
            ) : null}
            <div className="nav_others_all">
              <div className="nav-others-content">
                <Link
                  to="/store"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <div>Store</div>
                </Link>
                <Link
                  to="https://shop.pricefinder.in/contact-us/"
                  style={{ textDecoration: "none", color: "black" }}
                  target="_blank"
                >
                  <div>Contact</div>
                </Link>
              </div>
              <div className="nav-others-button">
                <Link
                  to="https://shop.pricefinder.in/vendor-register/"
                  style={{ textDecoration: "none", color: "black" }}
                  target="_blank"
                >
                  <button className="btn btn-success button_navigation_1">
                    Become a seller
                  </button>
                </Link>
                <Link
                  to="https://shop.pricefinder.in/my-account/"
                  style={{ textDecoration: "none", color: "black" }}
                  target="_blank"
                >
                  <button className="btn btn-success button_navigation_2">
                    Login
                  </button>
                </Link>
              </div>
            </div>
          </div>
          {/* Hamburger icon to toggle the menu */}
        </div>
      </section>
    </>
  );
}

export default Navigation;
