import React, { useState, useEffect, useRef } from "react";
import { BsStar } from "react-icons/bs";
import { GoMail } from "react-icons/go";
import { AiOutlinePhone, AiOutlineQuestionCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import axios from "axios";
import Footer from "../Footer/Footer";
import "./Store.css";
import { CgClose } from "react-icons/cg";
import Bannar from "../../assets/default_banner.jpg";

const Store = () => {
  const [vendors, setVendors] = useState([]);
  const [showInquiryPopup, setShowInquiryPopup] = useState(false);
  const [productSuggestions, setProductSuggestions] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [citySuggestions, setCitySuggestions] = useState([]);
  const [searchCity, setSearchCity] = useState("");
  const [showCategorySuggestions, setShowCategorySuggestions] = useState(true);
  const [showCitySuggestions, setShowCitySuggestions] = useState(true);
  const [isSuggestionSelected, setIsSuggestionSelected] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [selectedCitySuggestion, setSelectedCitySuggestion] = useState(-1);
  const [previousCitySuggestions, setPreviousCitySuggestions] = useState([]);

  const productSuggestionsRef = useRef(null);
  const citySuggestionsRef = useRef(null);

  const fetchVendorDetails = async () => {
    try {
      const consumerKey = process.env.REACT_APP_CONSUMER_KEY;
      const consumerSecret = process.env.REACT_APP_CONSUMER_SECRET;

      const url = `https://shop.pricefinder.in//wp-json/wcfmmp/v1/store-vendors/`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
        },
      });

      const vendorDetails = response.data;
      setVendors(vendorDetails);
      console.log(vendorDetails);
    } catch (error) {
      console.error("Error fetching vendor details:", error);
    }
  };

  const fetchVendorDetailsWithProductAndCity = async (category, city) => {
    try {
      const consumerKey = process.env.REACT_APP_CONSUMER_KEY;
      const consumerSecret = process.env.REACT_APP_CONSUMER_SECRET;

      const url = `https://shop.pricefinder.in/wp-json/custom-wc/v1/vendor-stores?tags[]=pro-${category}&tags[]=city-${city}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
        },
      });

      const vendorDetails = response.data;
      console.log("Vendor details:", vendorDetails.vendor_stores);
      setVendors(vendorDetails.vendor_stores);
    } catch (error) {
      console.error("Error fetching vendor details:", error);
    }
  };

  const fetchProductSuggestions = async (inputText) => {
    try {
      const consumerKey = process.env.REACT_APP_CONSUMER_KEY;
      const consumerSecret = process.env.REACT_APP_CONSUMER_SECRET;

      const url = `https://shop.pricefinder.in/wp-json/custom-wc/v1/tag-suggestions?input=pro-${inputText}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
        },
      });

      const productDetails = response.data;
      setProductSuggestions(productDetails);
      console.log("Product suggestions:", productDetails);
    } catch (error) {
      console.error("Error fetching product suggestions:", error);
    } finally {
      setShowCategorySuggestions(true);
    }
  };

  const fetchCitySuggestions = async (inputText) => {
    try {
      const consumerKey = process.env.REACT_APP_CONSUMER_KEY;
      const consumerSecret = process.env.REACT_APP_CONSUMER_SECRET;

      const url = `https://shop.pricefinder.in/wp-json/custom/v1/tag-suggestions1?input=pro-${inputText}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
        },
      });

      const cityDetails = response.data;
      setCitySuggestions(cityDetails);
      setPreviousCitySuggestions(cityDetails);
      setSelectedCitySuggestion(-1);
      console.log("City suggestions:", cityDetails);
    } catch (error) {
      console.error("Error fetching city suggestions:", error);
    } finally {
      setShowCitySuggestions(true);
    }
  };

  function handleSubmit(event) {
    event.preventDefault();

    // Collect form data
    const formData = new FormData(event.target);

    // Convert form data to JSON object
    const jsonObject = {};
    formData.forEach((value, key) => {
      jsonObject[key] = value;
    });

    // Send POST request to your desired endpoint
    fetch(
      "https://shop.pricefinder.in/wp-json/custom/v1/submit-freshdesk-ticket",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(jsonObject),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("Form submitted successfully:", data);
        setSelectedVendor(null);
        setShowInquiryPopup(false);
        alert("Thank you for your inquiry! We will get back to you soon.");
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
      });
  }

  const handleCitySuggestionNavigation = (e) => {
    const suggestions = citySuggestions;
    const lastIndex = suggestions.length - 1;

    switch (e.key) {
      case "ArrowUp":
        e.preventDefault();
        setSelectedCitySuggestion((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : lastIndex
        );
        break;
      case "ArrowDown":
        e.preventDefault();
        setSelectedCitySuggestion((prevIndex) =>
          prevIndex < lastIndex ? prevIndex + 1 : 0
        );
        break;
      case "Enter":
        e.preventDefault();
        if (selectedCitySuggestion !== -1) {
          const selectedSuggestionText = suggestions[selectedCitySuggestion];
          handleCitySuggestionClick(selectedSuggestionText);
        }
        break;
      default:
        break;
    }
  };

  const handleProductSuggestionClick = (suggestion) => {
    setSearchText(suggestion);
    setIsSuggestionSelected(true);
    setShowCategorySuggestions(true);
    fetchCitySuggestions(suggestion);
  };

  const handleCitySuggestionClick = (city) => {
    fetchVendorDetailsWithProductAndCity(searchText, city);
    setSearchCity(city);
    setIsSuggestionSelected(false);
    setShowCitySuggestions(false);
  };

  const handleInquiry = (vendor) => {
    setSelectedVendor(vendor);
    setShowInquiryPopup(true);
    console.log(vendor);
  };

  const handleCloseClick = () => {
    setSelectedVendor(null);
    setShowInquiryPopup(false);
  };

  const handleOverlayClick = (event) => {
    if (event.target.classList.contains("inquiry_overlay")) {
      handleCloseClick();
    }
  };

  useEffect(() => {
    fetchVendorDetails();
  }, []);

  const handleOutsideClick = (event) => {
    if (
      (productSuggestionsRef.current &&
        !productSuggestionsRef.current.contains(event.target) &&
        event.target !== document.getElementById("search-input")) ||
      (citySuggestionsRef.current &&
        !citySuggestionsRef.current.contains(event.target) &&
        event.target !== document.getElementById("city-search-input"))
    ) {
      setIsSuggestionSelected(false);
      setProductSuggestions([]);
      setCitySuggestions([]);
    }
  };

  const searchVendor = () => {
    if (searchText == "" && searchCity == "") {
      fetchVendorDetails();
    }
  };

  useEffect(() => {
    // Add event listener for outside click
    document.addEventListener("click", handleOutsideClick);

    // Remove event listener on component unmount
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <>
      <div className="store_search_content">
        <div className="store_search">
          <div className="store_search_category">
            <input
              type="search"
              placeholder="Search product..."
              value={searchText}
              onChange={(e) => {
                const inputText = e.target.value.toLowerCase();
                setSearchText(inputText);
                fetchProductSuggestions(inputText);
                setIsSuggestionSelected(false);
              }}
            />
            <ul
              className="suggestions"
              ref={productSuggestionsRef}
              style={{
                display:
                  searchText && showCategorySuggestions ? "block" : "none",
              }}
            >
              {productSuggestions.map((suggestion) => (
                <li
                  key={suggestion}
                  onClick={() => {
                    handleProductSuggestionClick(suggestion);
                    setShowCategorySuggestions(false);
                  }}
                >
                  {suggestion}
                </li>
              ))}
            </ul>
          </div>
          <div className="search_city">
            <input
              type="search"
              placeholder="Search city..."
              list="cityList"
              value={searchCity}
              id="city-search-input"
              onChange={(e) => {
                const inputText = e.target.value.toLowerCase();
                setSearchCity(inputText);
                const filteredSuggestions = previousCitySuggestions.filter(
                  (suggestion) => suggestion.toLowerCase().startsWith(inputText)
                );
                setCitySuggestions(filteredSuggestions);
                setIsSuggestionSelected(false);
              }}
              onClick={() => {
                setCitySuggestions(previousCitySuggestions);
                setIsSuggestionSelected(false);
              }}
              onKeyDown={handleCitySuggestionNavigation}
              disabled={previousCitySuggestions.length < 1}
            />
            <ul className="suggestions" ref={citySuggestionsRef}>
              {citySuggestions.map((suggestion, index) => {
                const isSelected = index === selectedCitySuggestion;
                return (
                  <li
                    onClick={() => handleCitySuggestionClick(suggestion)}
                    key={suggestion}
                    className={isSelected ? "selected" : ""}
                  >
                    {suggestion}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="store_search_button" onClick={searchVendor}>
            <span>
              <svg
                width="25"
                height="25"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10 17a7 7 0 1 0 0-14 7 7 0 0 0 0 14Z"></path>
                <path d="m21 21-6-6"></path>
              </svg>
            </span>
          </div>
        </div>
      </div>
      <div className="stores">
        <div className="store__list">
          {vendors.map(
            (vendor, index) =>
              (vendor.is_store_offline === "no" ||
                vendor.is_store_offline === "") &&
              vendor.disable_vendor !== "yes" && (
                <div className="store" key={vendor.vendor_id}>
                  <div
                    className="store_banner"
                    style={{
                      backgroundImage: `url(${
                        vendor.vendor_banner || vendor.list_banner || Bannar
                      })`,
                    }}
                  >
                    <div className="store_banner_content">
                      <div className="store_title">
                        <Link
                          to={`/store/${vendor.vendor_id}`}
                          style={{ textDecoration: "none", color: "#000" }}
                        >
                          <span className="store__title">
                            {vendor.vendor_shop_name ? (
                              <span>{vendor.vendor_shop_name}</span>
                            ) : (
                              <span>{vendor.vendor_name}</span>
                            )}
                          </span>
                        </Link>
                      </div>
                      <div className="store_rate">
                        <div className="store_rate_icons">
                          <span>
                            <BsStar />
                          </span>
                          <span>
                            <BsStar />
                          </span>
                          <span>
                            <BsStar />
                          </span>
                          <span>
                            <BsStar />
                          </span>
                          <span>
                            <BsStar />
                          </span>
                        </div>
                      </div>
                      {vendor.vendor_email && (
                        <div className="store_mail">
                          <div className="store_mail_back">
                            <GoMail />
                            <span>{vendor.vendor_email}</span>
                          </div>
                        </div>
                      )}
                      {vendor.vendor_phone && (
                        <div className="store_phone">
                          <div className="store_mail_back">
                            <AiOutlinePhone />
                            <span>{vendor.vendor_phone}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="store_visit">
                    <span onClick={() => handleInquiry(vendor)}>
                      <div className="inquiry_btn">
                        <AiOutlineQuestionCircle />
                        Inquiry
                      </div>
                    </span>
                    <Link to={`/store/${vendor.vendor_id}`}>
                      <div className="visit">VISIT</div>
                    </Link>
                  </div>
                  <div className="store_logo">
                    {vendor.vendor_shop_logo ? (
                      <img src={vendor.vendor_shop_logo} alt="logo" />
                    ) : (
                      <img src={vendor.vendor_logo} alt="logo" />
                    )}
                  </div>
                </div>
              )
          )}
          {showInquiryPopup && selectedVendor && (
            <div className="inquiry_overlay" onClick={handleOverlayClick}>
              <div className="inquiry_popup">
                <div className="popup__header">
                  <div className="popup__product">
                    {selectedVendor.vendor_shop_name ? (
                      <h3>{selectedVendor.vendor_shop_name}</h3>
                    ) : (
                      <h3>{selectedVendor.vendor_name}</h3>
                    )}
                  </div>
                  <button className="close_button" onClick={handleCloseClick}>
                    <CgClose />
                  </button>
                  <div className="product__logo">
                    {selectedVendor.vendor_shop_logo ? (
                      <img src={selectedVendor.vendor_shop_logo} alt="logo" />
                    ) : (
                      <img src={selectedVendor.vendor_logo} alt="logo" />
                    )}
                  </div>
                </div>

                <div className="heading_close">
                  <div className="heading_popup">
                    <h4>Ask a Question</h4>
                  </div>
                </div>

                <form onSubmit={handleSubmit} method="POST" className="form">
                  <input
                    type="text"
                    name="name"
                    placeholder="Your Name (required)"
                    required
                  />
                  <input
                    type="hidden"
                    name="subject"
                    value={selectedVendor.vendor_display_name}
                  />
                  <div className="input__email">
                    <input
                      type="email"
                      name="email"
                      placeholder="Your Email (required)"
                      required
                    />
                    <input
                      type="phone"
                      name="phone"
                      placeholder="Phone Number (required)"
                      required
                    />
                  </div>
                  <textarea
                    type="text"
                    className="textarea"
                    name="description"
                    required
                    placeholder="Your Message (required)"
                  ></textarea>
                  <div className="h-captcha" data-captcha="true"></div>
                  <button type="submit">SEND</button>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Store;
