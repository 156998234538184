import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./Cart.css";
import { AiOutlineShoppingCart } from "react-icons/ai";

const Cart = ({ itemCount, totalPrice, cartItems, productQuantity }) => {
  const [calculatedPrice, setCalculatedPrice] = useState(0);

  const formattedPrice =
    calculatedPrice < 0
      ? Number(Math.abs(calculatedPrice).toFixed(1))
      : Number(calculatedPrice.toFixed(1));

  const itemText = itemCount <= 1 ? "Item" : "Items";

  useEffect(() => {
    let total = 0;
    cartItems.forEach((item) => {
      total += item.price * (productQuantity[item.id] || 0);
    });
    setCalculatedPrice(total);
  }, [cartItems, productQuantity]);

  return (
    <div className="mini-cart">
      <div className="cart-icon">
        <AiOutlineShoppingCart />
        <div className="item-count">
          {itemCount} {itemText}
        </div>
      </div>
      <div className="total-price">₹{totalPrice}</div>
    </div>
  );
};

Cart.propTypes = {
  itemCount: PropTypes.number.isRequired,
  totalPrice: PropTypes.number.isRequired,
  cartItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
  productQuantity: PropTypes.object.isRequired,
};

export default Cart;
