import React from "react";
import Home from "./components/HomePage/HomePage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Shop from "./components/Store/Store";
import Vendor from "./components/Vendor/Vendor";
import Navigation from "./components/Navigation/nav";
import Footer from "./components/Footer/Footer";

function App() { 
  return (
    <>
      <Router>
        <Navigation />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/store" element={<Shop />} />
          <Route path="/store/:vendorId" element={<Vendor />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;