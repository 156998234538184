import React, { useState, useEffect, useRef } from "react";
import { IoMdTime } from "react-icons/io";
import { AiOutlinePhone } from "react-icons/ai";
import { GoMail } from "react-icons/go";
import { CiLocationOn } from "react-icons/ci";
import { GrFormNextLink, GrFormPreviousLink } from "react-icons/gr";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./Vendor.css";
import { useMemo } from "react";
import Cart from "../Cart/Cart";
import CartWindow from "../CartWindow/CartWindow";
import "../CartWindow/CartWindow.css";
import Navigation from "../Navigation/nav";
import Footer from "../Footer/Footer";

const Vendor = () => {
  const [category, setCategory] = useState([{ id: "all", name: "All" }]);
  const [products, setProducts] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [data, setData] = useState("All");
  const [showProducts, setShowProducts] = useState([]);
  const [productQuantity, setProductQuantity] = useState({});
  const [itemCount, setItemCount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [number, setnumber] = useState(1);
  const [pages, setPages] = useState([]);
  const [isCartPopupOpen, setIsCartPopupOpen] = useState(false);
  const [showvendor, setShowVendor] = useState({});
  const [hours, setHours] = useState([]);

  const productShowRef = useRef(null);

  const cartWindowRef = useRef(null);

  const { vendorId } = useParams();

  const fetchVendorDetails = async (vendorId) => {
    try {
      const consumerKey = process.env.REACT_APP_CONSUMER_KEY;
      const consumerSecret = process.env.REACT_APP_CONSUMER_SECRET;

      const url = `https://shop.pricefinder.in//wp-json/wcfmmp/v1/store-vendors/${vendorId}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
        },
      });

      const vendorDetails = response.data;
      setShowVendor(vendorDetails);
    } catch (error) {
      console.error("Error fetching city suggestions:", error);
    }
  };

  const fetchStoreHours = async (vendorId) => {
    try {
      const consumerKey = process.env.REACT_APP_CONSUMER_KEY;
      const consumerSecret = process.env.REACT_APP_CONSUMER_SECRET;

      const url = `https://shop.pricefinder.in/wp-json/custom/v1/store-hours/${vendorId}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
        },
      });

      const storeHours = response.data;
      setHours(storeHours);
    } catch (error) {
      console.error("Error fetching store hours:", error);
    }
  };

  const fetchcategory = async () => {
    try {
      const consumerKey = process.env.REACT_APP_CONSUMER_KEY;
      const consumerSecret = process.env.REACT_APP_CONSUMER_SECRET;
      const url = `https://shop.pricefinder.in/wp-json/custom-wc/v1/vendor-tags-by-id/${vendorId}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
        },
      });
      const categoryDetails = response.data;
      const allCategory = { id: "all", name: "All" };
      const categoriesWithAll = [allCategory, ...categoryDetails];
      setCategory(categoriesWithAll);
      setData("All");
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const handleCategoryChange = (categoryName) => {
    setData(categoryName);
    setnumber(1);
  };

  const fetchproducts = async () => {
    try {
      const consumerKey = process.env.REACT_APP_CONSUMER_KEY;
      const consumerSecret = process.env.REACT_APP_CONSUMER_SECRET;
      let url;
      if (data == "All") {
        url = `https://shop.pricefinder.in/wp-json/wcfmmp/v1/store-vendors/${vendorId}/products?page=${number}&per_page=12`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
          },
        });
        const productDetails = response.data;
        setProducts(productDetails);
        setShowProducts(productDetails);
        setPages(response.headers["x-wp-totalpages"]);
      } else {
        url = `https://shop.pricefinder.in/wp-json/custom-wc/v1/vendor-products-by-tags?vendor_id=${vendorId}&tags[]=${data}&page=${number}&per_page=12`;
        const response = await axios.get(url, {
          headers: {
            Authorization: `Basic ${btoa(`${consumerKey}:${consumerSecret}`)}`,
          },
        });
        const productDetails = response.data.products;
        setProducts(productDetails);
        setShowProducts(productDetails);
        setPages(response.data.total_pages);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const handleCartDesignClick = () => {
    setIsCartPopupOpen((prevIsOpen) => !prevIsOpen);
  };

  const removeItem = (itemId) => {
    const updatedCartItems = cartItems.filter((item) => item.id !== itemId);
    setCartItems(updatedCartItems);

    const updatedProducts = products.map((product) => {
      if (product.id === itemId) {
        return {
          ...product,
          addedToCart: false, // You can add a property to track if the product is in the cart
        };
      }
      return product;
    });
    setProducts(updatedProducts);
  };

  const handleCloseCartPopup = () => {
    setIsCartPopupOpen(false);
  };

  const handleNextPage = () => {
    if (productShowRef.current) {
      productShowRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    setnumber(number + 1);
  };

  const increaseQuantity = (productId) => {
    setProductQuantity((prevQuantity) => {
      const updatedQuantity = { ...prevQuantity };
      updatedQuantity[productId] = (prevQuantity[productId] || 0) + 1;
      if (!prevQuantity[productId]) {
        setItemCount((prevCount) => prevCount + 1);
      }
      // Find the product in products array using productId
      const product = products.find((item) => item.id === productId);
      if (product) {
        setTotalPrice((prevPrice) => prevPrice + Number(product.regular_price));
        // Add the product to the cartItems array if it's not already there
        if (!cartItems.find((item) => item.id === productId)) {
          setCartItems((prevCartItems) => [...prevCartItems, product]);
        }
      }

      return updatedQuantity;
    });
  };

  const decreaseQuantity = (productId) => {
    setProductQuantity((prevQuantity) => {
      if (prevQuantity[productId] && prevQuantity[productId] > 0) {
        const updatedQuantity = { ...prevQuantity };
        updatedQuantity[productId] = prevQuantity[productId] - 1;
        // Find the product in products array using productId
        const product = products.find((item) => item.id === productId);
        if (product) {
          setTotalPrice((prevPrice) => prevPrice - product.regular_price);
          // Remove the product from the cartItems array if its quantity becomes zero
          if (prevQuantity[productId] === 1) {
            setItemCount((prevCount) => prevCount - 1);
            setCartItems((prevCartItems) =>
              prevCartItems.filter((item) => item.id !== productId)
            );
          }
        }
        return updatedQuantity;
      }
      return prevQuantity;
    });
  };

  const getFormattedHours = (dayType) => {
    if (!hours || !hours.day_times) {
      return "N/A";
    }

    const dayStart = dayType === "Weekdays" ? 0 : 5;
    const dayEnd = dayType === "Weekdays" ? 4 : 6;

    const hoursForDayType = hours.day_times.slice(dayStart, dayEnd + 1);

    const formattedHours = hoursForDayType.map((dayTime) => {
      if (dayTime[0].start) {
        return `${dayTime[0].start} - ${dayTime[0].end}`;
      }
      return "Closed";
    });

    const uniqueHours = Array.from(new Set(formattedHours)); // Remove duplicates

    return uniqueHours.join(", ");
  };

  useEffect(() => {
    fetchcategory();
    fetchVendorDetails(vendorId);
    fetchStoreHours(vendorId);
    setData("All");
  }, [vendorId]);

  useEffect(() => {
    fetchproducts();
  }, [data, number]);

  return (
    <div className="vendor">
      <div className="vendor__info">
        <div className="vendor__banner">
          <h1 className="vendor__name">{showvendor.vendor_display_name}</h1>
        </div>
        <div className="vendor__address" ref={productShowRef}>
          <div className="address_vendor">
            <div className="vendor__addressline">
              <span>
                <CiLocationOn />
              </span>
              <p>{showvendor.vendor_address}</p>
            </div>
            <div className="vendor__phonemail">
              <div className="phone__vendor">
                <span>
                  <AiOutlinePhone />
                </span>
                <p>{showvendor.vendor_phone}</p>
              </div>
              <div className="mail__vendor">
                <span>
                  <GoMail />
                </span>
                <p>pricefinder.in@gmail.com</p>
              </div>
            </div>
          </div>
          {/* <div className="store__hours">
            <p>
              {" "}
              <span>
                <IoMdTime />
              </span>{" "}
              Weekdays (Monday - Friday ) : 09.00 - 21.00
            </p>

            <p>
              {" "}
              <span>
                <IoMdTime />
              </span>{" "}
              Weekend (Saturday & Sunday ) : 09.00 - 21.00
            </p>
          </div> */}
          <div className="store__hours">
            <p>
              {" "}
              <span>
                <IoMdTime />
              </span>{" "}
              Weekdays (Monday - Friday ) : {getFormattedHours("Weekdays")}
            </p>

            <p>
              {" "}
              <span>
                <IoMdTime />
              </span>{" "}
              Weekend (Saturday & Sunday ) : {getFormattedHours("Weekend")}
            </p>
          </div>
        </div>

        <div className="vendor__logo">
          <img src={showvendor.vendor_shop_logo} alt="" />
        </div>
      </div>

      <hr />
      <div className="category__products">
        <div className="category category-container">
          <div className="container-fluid mt-3">
            <div className="row">
              <div className="col-md-2 buttonsmenu all_filter_sticky  mt-2">
                <div className="category__list">
                  {category.map((value) => (
                    <div key={value.id} className="row">
                      <div
                        className={`col-12 mt-2 btns category__name ${
                          value.name === data ? "active" : ""
                        }`}
                        onClick={() => {
                          handleCategoryChange(value.name);
                          setData(value.name);
                          if (productShowRef.current) {
                            productShowRef.current.scrollIntoView({
                              behavior: "smooth",
                              block: "start",
                            });
                          }
                        }}
                      >
                        {value.name.replace("cat-", "")}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-md-10 products">
                <div className="row mt-2">
                  {products.map((values, index) => (
                    <div
                      key={values.id}
                      className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12 mb-4"
                    >
                      <div className="card">
                        {values.image ? (
                          <img
                            src={values.image}
                            className="card-img-top product-image"
                            alt={values.name}
                          />
                        ) : (
                          <img
                            src={values.images[0].src}
                            className="card-img-top product-image"
                            alt={values.name}
                          />
                        )}

                        <div className="card-body">
                          <h5 className="card-price">
                            {values.regular_price != "" &&
                            values.regular_price > values.sale_price ? (
                              <span>
                                ₹{Number(values.regular_price).toFixed(2)}
                              </span>
                            ) : (
                              <span>
                                ₹{Number(values.sale_price).toFixed(2)}
                              </span>
                            )}{" "}
                            {values.regular_price != "" &&
                            values.regular_price < values.sale_price ? (
                              <span style={{ textDecoration: "line-through" }}>
                                ₹{Number(values.regular_price).toFixed(2)}
                              </span>
                            ) : null}
                          </h5>

                          <p className="card-text">
                            {values.name.length > 25
                              ? `${values.name.slice(0, 25)}...`
                              : values.name}
                          </p>
                          <div
                            className={`addtocard ${
                              productQuantity[values.id] > 0 ? "active" : ""
                            }`}
                          >
                            {productQuantity[values.id] > 0 && (
                              <div
                                className="sub-btn"
                                onClick={() => decreaseQuantity(values.id)}
                              >
                                -
                              </div>
                            )}
                            {productQuantity[values.id] > 0 && (
                              <div className="quantity-btn">
                                {productQuantity[values.id]}
                              </div>
                            )}
                            <div
                              className="cartadd"
                              onClick={() => increaseQuantity(values.id)}
                              style={{
                                display:
                                  productQuantity[values.id] > 0
                                    ? "none"
                                    : "block",
                              }}
                            >
                              Add
                            </div>
                            <div
                              className="add-sym"
                              onClick={() => increaseQuantity(values.id)}
                            >
                              +
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="navigation_button">
                  <div className="page_nav">
                    {number > 1 && pages > 1 ? (
                      <button
                        className="btn btn-info"
                        onClick={() => {
                          if (productShowRef.current) {
                            productShowRef.current.scrollIntoView({
                              behavior: "smooth",
                              block: "start",
                            });
                          }

                          setnumber(number - 1);
                        }}
                      >
                        Previous <GrFormPreviousLink />
                      </button>
                    ) : (
                      <button className="btn btn-info" disabled>
                        Previous
                      </button>
                    )}
                    <span className="page__number">
                      {number} of {pages}
                    </span>
                    {pages > number ? (
                      <button className="btn btn-info" onClick={handleNextPage}>
                        Next <GrFormNextLink />
                      </button>
                    ) : (
                      <button className="btn btn-info" disabled>
                        Next
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cart-design" onClick={handleCartDesignClick}>
        <Cart
          itemCount={itemCount}
          totalPrice={totalPrice}
          cartItems={cartItems}
          productQuantity={productQuantity}
        />
      </div>
      <CartWindow
        isOpen={isCartPopupOpen}
        onClose={handleCloseCartPopup}
        itemCount={itemCount}
        totalPrice={totalPrice}
        cartItems={cartItems}
        productQuantity={productQuantity}
        decreaseQuantity={decreaseQuantity}
        increaseQuantity={increaseQuantity}
        removeItem={removeItem}
        // ref={cartWindowRef}
      />
      <Footer />
    </div>
  );
};

export default Vendor;
